<template>
    <v-chip :color="color" :text-color="textColor" v-if="text" style="max-width: min-content;">
        <v-icon v-if="icon" :color="iconColor">{{ icon }}</v-icon>
        <v-img v-if="image" :src="image" height="16px" width="16px" contain class="px-3" />
        {{ text }}
    </v-chip>
</template>


<script>
const initialState = {
    color: "grey lighten-4",
    textColor: "black",
    text: "Unknown",
    icon: "",
    iconColor: "grey",
    image: null
}

export const userTypesConfig = {
    "ADMIN": {
        ...initialState,
        text: "Administrator",
        image: require("/public/logo-icon.png"),
    },
    "SUPER_ADMIN": {
        ...initialState,
        text: "Super Admin",
        filter: [{
            field: "isGoogleSuperAdmin",
            value: true,
        }]
    },
    "USER": {
        ...initialState,
        text: "User",
    },
    "SERVICE": {
        ...initialState,
        text: "Service account",
    },
}

export default {
    name: "UserSecureStatus",
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            ...initialState,
        }
    },
    mounted() {
        this.update()
    },
    methods: {
        reset() {
            this.color = initialState.color
            this.textColor = initialState.textColor
            this.text = initialState.text
            this.icon = initialState.icon
            this.iconColor = initialState.iconColor
            this.image = initialState.image
        },
        update() {
            const config = userTypesConfig[this.user.type]

            if (config) {
                this.color = config.color
                this.textColor = config.textColor
                this.text = config.text
                this.icon = config.icon
                this.iconColor = config.iconColor
                this.image = config.image
            } else {
                this.reset()
                this.text = ""
            }
        }
    },
}
</script>
