<template>
    <div v-show="accessGrid.getNumChanges(status)">
        <ColoredChip class="ma-4 d-flex" :type="type">
            {{ text }}
        </ColoredChip>
        <v-divider />
        <div class="pa-0 overflow-hidden">
            <div v-for="(userChanges, userId) of accessGrid.getChangeMatrix()" :key="`change-${userId}`" class="px-0 py-0">
                <div v-if="userHasChanges(userId)">
                    <v-row class="text-body-2" no-gutters>
                        <v-col cols="5" no-gutters>
                            <v-row class="py-3 pl-4" style="align-self: center; height: 60px;" no-gutters>
                                <UserCell :item="accessGrid.getUserAccess(userId) || {}" />
                            </v-row>
                        </v-col>
                        <v-col cols="7" no-gutters>
                            <div v-for="(appChange, appId) of userChanges" :key="`app-${userId}-${appId}`">
                                <v-row class="py-3 px-4" no-gutters v-if="appChange.status === status">
                                    <v-col cols="10" no-gutters style="align-self: center;">
                                        <ApplicationNameCell :application="accessGrid.getApplication(appId)" />
                                    </v-col>
                                    <v-col cols="2" no-gutters style="align-self: center; text-align: right;">
                                        <v-btn @click="() => accessGrid.undo(userId, appId)"
                                            icon><v-icon>mdi-close</v-icon></v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                    <v-divider />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ColoredChip from "@/components/ColoredChip.vue";
import UserCell from "./UserCell.vue";
import ApplicationNameCell from "@/components/table/ApplicationNameCell.vue";

export default {
    components: {
        UserCell,
        ApplicationNameCell,
        ColoredChip,
    },
    props: {
        accessGrid: {
            required: true,
        },
        status: {
            type: String,
            default: "ADD",
        }
    },
    computed: {
        type() {
            if (this.status === "REMOVE") {
                return "error"
            }
            if (this.status === "ADD") {
                return "success"
            }
            return ""
        },
        text() {
            if (this.status === "REMOVE") {
                return "Remove application for"
            }
            if (this.status === "ADD") {
                return "Add application for"
            }
            return ""
        },
    },
    methods: {
        userHasChanges(userId) {
            return this.accessGrid.userHasChanges(userId, this.status)
        }
    }
}
</script>