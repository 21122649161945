<template>
    <div>
        <v-layout class="pb-4">
            <v-spacer/>
            <TableSearchBar :search.sync="search" />
            <RefreshTable @click="getAccessRequests" />
            <ReorderTableHeader :tableHeaders.sync="accessRequestHeaders" tableName="accessRequests"/>
            <TableMenu :tableHeaders.sync="accessRequestHeaders" :tableData="accessRequests" csvFilename="access-request-log" />
        </v-layout>
        <v-data-table data-cy="access-requests" ref="accessRequests" :search="search" :headers.sync="accessRequestHeaders" :items="accessRequests" :loading="loading">
            <template #[`item.requestTime`]="{ item }">
                <DateFormat v-if="item.requestTime" :dateString="item.requestTime" />
            </template>
            <template #[`item.responseTime`]="{ item }">
                <DateFormat v-if="item.responseTime" :dateString="item.responseTime" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import DateFormat from "@/components/DateFormat.vue";
import RefreshTable from "@/components/RefreshTable.vue";
import ReorderTableHeader from "@/components/ReorderTableHeader.vue";
import TableMenu from "@/components/TableMenu.vue";
import TableSearchBar from "@/components/TableSearchBar.vue";

export default {
    data() {
        return {
            loading: false,
            accessRequests: [],
            accessRequestHeaders: [{
                text: "Type",
                value: "requestType",
            }, {
                text: "Request Date",
                value: "requestTime",
            }, {
                text: "Requester",
                value: "requesterEmail",
            }, {
                text: "Requester Note",
                value: "requesterNotes",
            }, {
                text: "Subject",
                value: "subjectEmail",
            }, {
                text: "Application",
                value: "applicationName",
            }, {
                text: "Response Date",
                value: "responseTime",
            }, {
                text: "Responder",
                value: "responderEmail",
            }, {
                text: "Responder Note",
                value: "responderNotes",
            }, {
                text: "Response to Request",
                value: "result",
            }],
            search: "",
        }
    },
    mounted() {
        this.getAccessRequests()
    },
    methods: {
        getAccessRequests() {
            this.accessRequests = []
            this.loading = true

            this.$http.get('/api/v1/events/access-requests').then(resp => {
                if (this.accessRequests) {
                    this.accessRequests.push(...resp.data.events)
                } else {
                    this.accessRequests = resp.data.events
                }
            }).then(() => {
                this.$http.get('/api/v1/applications/access-requests').then(resp => {
                    if (this.accessRequests) {
                        this.accessRequests.push(...resp.data.accessRequests)
                    } else {
                        this.accessRequests = resp.data.accessRequests
                    }
                })
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                this.loading = false
            })
        },
    },
    components: {
        DateFormat,
        TableSearchBar,
        RefreshTable,
        ReorderTableHeader,
        TableMenu
    },
    metaInfo: {
        title: 'Access - YeshID'
    }
}
</script>
