<template>
    <v-menu
        :value="open"
        :close-on-content-click="false"
        offset-y
        >
        <template v-slot:activator="{ attrs, on }">
            <v-chip
                class="my-1"
                color="grey lighten-4"
                v-bind="attrs"
                :input-value="internalFilter.selected"
                close
                @click="open = true"
                @click:close="$emit('remove')"
                @hook:mounted="delayedOpen()" 
                v-on="on">
                {{ `${internalFilter.text } is ${getSelectedText(internalFilter)}` }}
                <v-icon>mdi-chevron-down</v-icon>
            </v-chip>
        </template>
        <v-sheet class="pa-4">
            <FormTextField
                v-model="search"
                :label="internalFilter.text"
            />
            <v-list maxHeight="250px" style="overflow-y: auto;">
                <v-list-item-group>
                    <template v-for="(item, i) in filteredItems()">
                        <v-list-item
                            :key="`filter-dropdown-selector-${i}`"
                            @click="() => checkboxClick(item)"
                            dense
                        >
                            <template v-slot:default="{}">
                                <v-list-item-action>
                                    <v-checkbox
                                        :input-value="isSelected(item)"
                                        color="primary"
                                        readonly
                                    ></v-checkbox>
                                </v-list-item-action>

                                <v-list-item-content v-if="$scopedSlots.item">
                                    <slot name="item" v-bind="item"/>
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-sheet>
    </v-menu>
</template>



<script>
import FormTextField from '../FormTextField.vue';

export default {
    props: {
        filter: {
            type: Object,
            default: Object,
        },
        initialFilters: {
            type: Array,
            default: Array,
        }
    },
    data() {
        return {
            open: false,
            search: "",
            internalFilter: this.filter,
        };
    },
    mounted() {
        this.applyInitialFilters()
    },
    watch: {
        "internalFilter.items": function(newItems, oldItems) {
            // apply initial filters in case the filter items are populated later
            if (newItems.length !== 0 && oldItems.length === 0) {
                this.applyInitialFilters()
            }
        }
    },
    methods: {
        // FIXME: for some reason the menu off the chip renders before the chip
        // this causes the menu to be positioned on the top left of the screen
        // hence the delay to open the menu
        delayedOpen() {
            setTimeout(() => {
                // don't open filter menu if filters were pre-applied from url query
                if (!this.anyFiltersSelected() && !this.initialFilters.length) {
                    this.open = true
                }
            }, 1)
        },
        applyInitialFilters() {
            if (!this.internalFilter.items || !this.internalFilter.items.length) {
                // for some reason this is being mounted twice and sometimes doesn't have items
                return
            }

            for (const filter of this.initialFilters) {
                const item = this.findItem(filter)
                if (item) {
                    item.op = filter.op
                    this.checkboxClick(item, false)
                }
            }

            this.$emit("initialApplied", this.internalFilter)
        },
        anyFiltersSelected() {
            return this.internalFilter.selected.length > 0
        },
        findItem(filter) {
            return this.internalFilter.items.find(item => item.value === filter.value && item.op === filter.op)
        },
        filteredItems() {
            return this.internalFilter.items.filter(item => !this.search || item.text.toLocaleLowerCase().indexOf(this.search.toLocaleLowerCase()) > -1)
        },
        isSelected(item) {
            return this.internalFilter.selected.filter(s => s.value === item.value && s.op === item.op).length > 0
        },
        checkboxClick(item, apply = true) {
            if (this.isSelected(item)) {
                this.internalFilter.selected = this.internalFilter.selected.filter(s => s.value !== item.value)
            } else {
                this.internalFilter.selected = [...this.internalFilter.selected, item]
            }

            this.internalFilter = { ...this.internalFilter }

            if (apply) {
                this.$emit("applied", this.internalFilter);
            }
        },
        getSelectedText(item) {
            const selected = item.selected;

            if (selected.length) {
                return item.selected.map(a => `${a.op === "!=" ? "not " : ""}"${a.text}"`).join(", ");
            }

            return "";
        },
    },
    components: { FormTextField }
}
</script>