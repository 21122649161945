<template>
    <div class="d-flex align-center">
        <v-tooltip v-bind="$props" v-on="$listeners" :max-width="maxWidth" color="black">
            <template #activator="{ on }">
                <v-icon v-on="on" size="20">mdi-information-outline</v-icon>
            </template>
            <slot name="text">{{ text }}</slot>
        </v-tooltip>
    </div>
</template>

<script>
import {VTooltip} from "vuetify/lib";

export default {
    extends: VTooltip,
    props: {
        text: {
            type: String,
            default: ""
        },
        maxWidth: {
            default: 400,
        }
    }
}
</script>