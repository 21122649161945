<template>
    <SidePanel :visible="visible" :close="closePanel" v-on="on">
        <template slot="prepend-title"><ApplicationIcon :application="application" :size="36"/></template>
        <template slot="title">{{ application.name == '' ? application.domain : application.name }} </template>
        <template slot="append-title">
            <v-chip v-if="application.managed">managed</v-chip>
            <v-chip v-else-if="application.forbidden" color="error">forbidden</v-chip>
            <v-chip v-else>unmanaged</v-chip>
        </template>
        <template slot="actions">
            <v-btn v-if="!application.managed && !application.forbidden" elevation="0" text plain outlined class="mr-4" @click="addApplication">Add as managed application</v-btn>
            <v-btn v-if="!application.managed" elevation="0" outlined @click="revokeAccess" color="error-text" class="mr-4">Revoke access</v-btn>
            <v-btn v-if="!application.managed" elevation="0" outlined @click="blockApp" color="error-text">Block</v-btn>
        </template>
        <template slot="tabs">
            <v-tabs v-model="selectedTab">
                <v-tab v-if="application.applicationId" href="#details">Details</v-tab>
                <v-tab href="#users">Users</v-tab>
                <v-tab href="#scopes">Scopes</v-tab>
            </v-tabs>
        </template>
        <v-tabs-items v-model="selectedTab">
            <v-tab-item value="details">
                <TableList title="Details" :data="getDetails()" />
            </v-tab-item>

            <v-tab-item value="users">
                <v-layout class="mb-4" align="end">
                    <v-spacer></v-spacer>
                    <TableSearchBar :search.sync="search.users" />
                </v-layout>
                <SecurityUsers :tokenName="application.name" :client-ids="application.clientIds" :search="search.users" v-if="application.name" />
            </v-tab-item>

            <v-tab-item value="scopes">
                <v-layout class="mb-4" align="end">
                    <v-spacer></v-spacer>
                    <TableSearchBar :search.sync="search.scopes" />
                </v-layout>
                <SecurityScopes :client-ids="application.clientIds" :tokenName="application.name"/>
            </v-tab-item>
        </v-tabs-items>
        <RevokeThirdPartyAppAccessDialog :toggle.sync="revokeAccessDialog" :application="application" @success="() => { $emit('grantRevoked'); closePanel(); }"/>
        <AddApplicationDialog :toggle.sync="addApplicationDialogToggle" :preselected="{ ...application, id: application.applicationId }" @addApplicationComplete="appWasAdded" />
        <BlockThirdPartyApp :toggle.sync="blockAppDialog" :application="application"/>
    </SidePanel>
</template>

<script>
import SecurityScopes from './SecurityScopes.vue'
import SecurityUsers from './SecurityUsers.vue'
import ApplicationIcon from '@/components/ApplicationIcon.vue'
import SidePanel from '@/components/SidePanel.vue'
import TableSearchBar from '@/components/TableSearchBar.vue'
import TableList from '@/components/TableList.vue'
import AddApplicationDialog from '../Application/AddApplicationDialog.vue'
import RevokeThirdPartyAppAccessDialog from './RevokeThirdPartyAppAccessDialog.vue'
import BlockThirdPartyApp from './BlockThirdPartyApp.vue'

export default {

    components: {
    ApplicationIcon,
    SidePanel,
    TableSearchBar,
    TableList,
    SecurityUsers,
    SecurityScopes,
    AddApplicationDialog,
    RevokeThirdPartyAppAccessDialog,
    BlockThirdPartyApp
},
    props: {
        application: {
            type: Object,
            required: true
        },
        tab: {
            type: String,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            selectedTab: 'details',
            search: {
                users: "",
                scopes: "",
            },
            addApplicationDialogToggle: false,
            revokeAccessDialog: false,
            blockAppDialog: false,
        }
    },
    methods: {
        closePanel: function () {
            this.$emit('update:visible', false)
            this.$emit('close')
        },
        addApplication: function() {
            this.addApplicationDialogToggle = true
        },
        revokeAccess: function() {
            this.revokeAccessDialog = true
        },
        appWasAdded: function() {
            this.$emit('applicationWasAdded', this.application)
        },
        blockApp: function() {
            this.blockAppDialog = true
        },
        getDetails: function() {
            return [
                { label: "Description", value: this.application.description },
                { label: "Website", value: this.application.domain },
                { label: "Labels", value: this.application.labels, type: "labels" },
            ]
        },
    },
    computed: {
        applicationIcon: function () {
            if (this.application.domain != '' && this.application.name != '') {
                return 'https://logo.clearbit.com/' + this.application.domain + '?size=40'
            } 
            let unknown = require('@/assets/unknown.svg')
            return unknown
        },
        on() {
            if (this.$listeners.back) {
                return { back: () => this.$emit('back') }
            }

            return {}
        }
    },
    watch: {
        tab: function (tab) {
            this.selectedTab = tab
        },
    },
}

</script>

