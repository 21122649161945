<!-- component to allow icon uploading -->

<template>
    <div>
        <v-layout class="grid-layout grid-basis-auto align-center">
            <slot name="activator" v-bind="{ on: { click: handleFileUpload }}"><button v-if="edit" @click.stop.prevent="handleFileUpload"></button></slot>
            <input type="file" ref="file" style="display: none" @input="fileChanged" :accept="fileFormat" />
        </v-layout>
        <div v-if="error" class="text-caption error-text--text mt-2">{{ error }}</div>
    </div>
</template>

<script>
    export default {
        name: "IconFileInput",
        props: {
            edit: {
                type: Boolean,
                required: false,
                default: true,
            },
            fileFormat: {
                type: String,
                required: true,
                default: "",
            },
            fileFormatError: {
                type: String,
                default: "Invalid file format"
            },
            maxSize: {
                type: Number,
                default: 1024 * 1024,
            }
        },
        data() {
            return {
                file: null,
                error: "",
            }
        },

        methods: {
            handleFileUpload() {
                this.$refs.file.click()
            },
            clearFile() {
                this.$refs.file.value = null
            },
            fileChanged(e) {
                this.error = ""
               
                const reader = new FileReader();
                reader.readAsDataURL(e.target.files[0]);

                reader.onload = (event) => {
                    const result = event?.target?.result || ""
                    const validType = result.includes(this.fileFormat)

                    if (!validType) {
                        this.error = this.fileFormatError
                        return
                    }

                    if (event.total > this.maxSize) {
                        this.error =  `File too large. The file size must be less than ${this.maxSize / 1024 / 1024} MB. Please choose a smaller file.`
                        return
                    }

                    this.$emit('input', reader.result);
                    this.$emit('file', e.target.files[0]);
                };

                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    this.error = 'Unable to read the file. Please ensure the file is not corrupted and try uploading again.'
                };

            }
        }
    }

</script>