<template>
    <v-chip :color="color" v-if="text" style="max-width: min-content;">
        <v-icon v-if="icon" :color="iconColor">{{ icon }}</v-icon>
        {{ text }}
    </v-chip>
</template>

<script>

export const statuses = {
    "ACTIVE": {
        text: "Active",
        color: "success-bg",
        icon: "mdi-circle-medium",
        iconColor: "#67AD5B",
    },
    "DEACTIVATED": {
        text: "Deactivated",
        color: "#FFE8EB",
        icon: "mdi-circle-medium",
        iconColor: "red",
    },
    "SUSPENDED": {
        text: "Suspended",
        color: "#FFE8EB",
        icon: "mdi-circle-medium",
        iconColor: "red",
    },
}

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            ...statuses["ACTIVE"],
        }
    },
    mounted() {
        this.update();
    },
    methods: {
        update() {
            const config = statuses[this.user.status]

            this.color = config.color
            this.text = config.text
            this.icon = config.icon
            this.iconColor = config.iconColor
        }
    },
    watch: {
        user: function() {
            this.update()
        }
    },
}

</script>
