<template>
    <div>
        <InputLabelDescription :label="label" :optional="optional" :description="description" :info="info"/>
        <v-text-field :data-cy="dataCy" v-bind="$props" v-on="$listeners" @keydown="keydown" class="text-body-2" :label="unfocusedPlaceholder" dense single-line validate-on-blur
            clear-icon="mdi-close-circle" v-show="edit">
            <slot v-for="(_, name) in $scopedSlots" :name="name" :slot="name"></slot>
            <template v-if="showValid" #append>
                <v-icon v-if="passesRules" size="18" color="success">mdi-check-circle</v-icon>
            </template>
        </v-text-field>

        <div v-show="!edit">
            <template v-if="value">
                <a :data-cy="dataCy + '-link'" v-if="link" :href="value" target="_blank" class="text-body-2" style="text-decoration: none;">{{ value }}</a>
                <span :data-cy="dataCy + '-label'" v-else class="text-body-2">
                    <span v-if="type === 'password'">********</span>
                    <span v-else-if="type === 'secret'">{{value}}</span>
                    <span v-else>{{ value }}</span>
                </span>
            </template>
        </div>
    </div>
</template>


<script>
import { VTextField } from 'vuetify/lib'
import InputLabelDescription from './InputLabelDescription.vue';

export const SECRET_VALUE = '-- Secret Value --'

export default {
    name: "FormTextInput",
    extends: VTextField,
    props: {
        label: {
            type: String,
            default: "",
        },
        optional: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        "data-cy": {
            type: String,
            default: "",
        },
        edit: {
            type: Boolean,
            default: true,
            required: false,
        },
        link: {
            type: Boolean,
            default: false,
            required: false,
        },
        description: {
            type: String,
            required: false,
            default: "",
        },
        info: {
            type: String,
            required: false,
            default: ""
        },
        hideDetails: {
            type: [String, Boolean],
            default: "auto",
            required: false,
        },
        unfocusedPlaceholder: {
            type: String,
            default: "",
        },
        showValid: {
            type: Boolean,
            default: false,
        }
    },
    components: { InputLabelDescription },
    methods: {
        keydown() {
            if (this.type === 'secret' && this.value === SECRET_VALUE) {
                this.$emit('input', '')
            }
        }
    },
    computed: {
        passesRules() {
            if (this.showValid && this.rules.length) {
                const failsSomeRules = this.rules.some(r => r(this.value) !== true)
                return this.showValid && !failsSomeRules
            }
            return false
        }
    }
}
</script>