<template>
    <div>
        <slot name="activator" v-bind="{ on: { click: exportCSV } }"/>
    </div>
</template>

<script>
export default {
    props: {
        tableHeaders: {
            type: Array,
            required: true
        },
        tableData: {
            type: Array,
            required: true
        },
        csvFilename: {
            type: String,
            required: true,
        }
    },
    methods: {
        exportCSV() {
            let data = this.tableData;
            let headers = this.tableHeaders;
            let filename = this.csvFilename + '.csv';
            let csv = '';
            // add the header row
            headers.forEach(h => {
                csv += h.text + ',';
            });
            csv = csv.slice(0, -1);
            csv += '\n';
            // add the data rows
            data.forEach(row => {
                headers.forEach(h => {
                    // escape the data
                    let colVal = row[h.value] == undefined || row[h.value] == null ? "" : row[h.value];
                    if (h.textFormatter) {
                        colVal = h.textFormatter(colVal);
                    }
                    csv += '"' + colVal.toString().replace(/"/g, '""') + '",';
                });
                // trim the trailing comma
                csv = csv.slice(0, -1);
                csv += '\n';
            });
            // create a download link and click it to download the csv
            let downloadLink = document.createElement('a');
            downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
        },
    },
}

</script>
