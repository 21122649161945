<template>
    <v-dialog :value="show" persistent scrollable :width="width" max-width="95%" eager @keydown.esc="close ? close : null">
        <v-card>
            <!-- header -->
            <div v-if="close || title || $scopedSlots.title">
                <v-card-title class="px-4 py-2">
                    <div>
                        <slot name="title">{{ title }}</slot>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn v-if="close" :disabled="loading" icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>
            </div>
            <!-- content -->
            <v-card v-if="$scopedSlots['prepend-content']" class="pa-0"
                style="border-radius: 0; border-bottom: 1px solid #e0e0e0; box-shadow: none;">
                <slot name="prepend-content"/>
            </v-card>

            <!-- content -->
            <v-card class="pa-6" style="overflow-y: auto; border-radius: 0; box-shadow: none;">
                <slot name="content"/>
            </v-card>

            <!-- alerts/important info -->
            <v-card style="border-radius: 0; box-shadow: none;">
                <v-alert v-if="$scopedSlots.info" class="ma-0" text color="blue" icon="mdi-information">
                    <slot name="info"/>
                </v-alert>
                <v-alert v-if="$scopedSlots.error" class="ma-0" text color="red" icon="mdi-alert">
                    <slot name="error"/>
                </v-alert>
                <v-alert v-if="$scopedSlots.warn" class="ma-0" text type="warning" icon="mdi-alert">
                    <slot name="warn"/>
                </v-alert>
            </v-card>

            <!-- card actions stepper -->
            <v-card v-if="$scopedSlots.actions" color="flex-shrink-0"
                style="border-radius: 0; border-top: 1px solid #e0e0e0; box-shadow: none;">
                <v-card-actions class="grey lighten-5 px-4 py-3">
                    <v-spacer></v-spacer>
                    <slot name="actions"/>
                </v-card-actions>
            </v-card>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        close: {
            type: Function,
        },
        width: {
            default: "800px"
        },
        title: {
            type: String,
            default: ""
        }
    },
};
</script>