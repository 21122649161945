<template>
    <v-chip class="base-chip px-3" v-bind="$props" v-on="$listeners"
        outlined :close="!!$listeners['click:close']">
        <slot/>
    </v-chip>
</template>

<script>

import { VChip } from 'vuetify/lib';

export default {
    name: "BaseChip",
    extends: VChip,
}

</script>
