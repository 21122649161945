<template>
    <v-avatar :color="color ? color : pickColor()" class="white--text" :size="size" left>
        <img v-if="user.photoMimeType && !imgFailed" :src="getImgSrc" v-on:error="imgFailed = true"/>
        <span v-else>{{ getInitials() }}</span>
    </v-avatar>
</template>

<script>

export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            required: false,
            default: 24,
        },
        color: {
            type: String,
            required: false,
        }
    },
    data() {
        return {
            imgFailed: false,
        }
    },
    computed: {
        getImgSrc() {
            // leaving this here for reference
            return `data:${this.user.photoMimeType};base64,${this.user.photoData.replaceAll("_","/").replaceAll("-","+").replaceAll("*","=").replaceAll(".","=")}`
        },
    },
    methods: {
        getInitials() {
            let initials = "?"

            try {
                if (this.user.name) {
                    initials = (this.user.name.charAt(0) || "").charAt(0) + (this.user.lastName || "").charAt(0)
                    initials = initials.toUpperCase()
                }
            } catch {
                console.log("could not get user initials")
            }

            return initials
        },
        pickColor() {

            if (!this.user.id) {
                return "grey"
            }

            // fiddle with these ranges to get the desired color range
            let hRange = [0, 360];
            let sRange = [45, 80];
            let lRange = [30, 60];

            // xxx: some user lists have a userId and some have an id, so we need to check both
            let hash = this.getHashOfString(this.user.id);
            let h = this.normalizeHash(hash, hRange[0], hRange[1]);
            let s = this.normalizeHash(hash, sRange[0], sRange[1]);
            let l = this.normalizeHash(hash, lRange[0], lRange[1]);
            return `hsl(${h}, ${s}%, ${l}%)`;

        },
        normalizeHash(hash, min, max) {
            return Math.floor((hash % (max - min)) + min);
        },
        getHashOfString(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            hash = Math.abs(hash);
            return hash;
        }
    },
    watch: {
        user: function () {
            this.imgFailed = false
        },
    }
}

</script>