<template>
    <tr>
        <td class="font-weight-bold py-3" style="width: 200px; white-space: nowrap; vertical-align: top;">{{ label }}</td>

        <td class="py-2">
            <template v-if="$slots.default"><slot/></template>
            <template v-else-if="value">{{ value }}</template>
            <template v-else>&ndash;</template>
        </td>
    </tr>
</template>


<script>
export default {
    name: "TableListItem",

    props: {
        label: {
            type: String,
            default: ""
        },
        value: {
            default: ""
        },
    },
}
</script>