<template>
    <BaseChip v-if="user.id" v-bind="$props" v-on="$listeners">
        <UserAvatar :user="user"/>
        <UserName :user="user" :showEmail="showEmail"/>
        <slot name="action"/>
    </BaseChip>
</template>

<script>

import UserAvatar from '@/components/UserAvatar.vue'
import UserName from '@/components/UserName.vue'
import BaseChip from './BaseChip.vue';

export default {
    name: "UserChip",
    extends: "BaseChip",
    components: {
        UserAvatar,
        UserName,
        BaseChip
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: Object,
        },
        showEmail: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        console.log(this.user.id, this.user.fullName)
    }
}


</script>

<style scoped>
.base-chip.v-chip .v-avatar {
    margin-left: -8px;
}
</style>