<template>
    <ExpandableSection :alwaysShow="!expandable">
        <template slot="header">
            <v-layout class="mb-4" justify-space-between align-center>
                <slot name="title">
                    <h6 v-if="title" class="px-0">
                        {{ title }}
                    </h6>
                </slot>
                <slot name="headerActions"/>
            </v-layout>
        </template>
        <template slot="content">
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <template v-for="item of data">
                            <TableListItem :key="item.label" :label="item.label" :value="item.value">
                                <slot v-if="$scopedSlots[item.type]" :name="item.type" v-bind="item"/>
                            </TableListItem>
                        </template>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
    </ExpandableSection>
</template>

<script>
import TableListItem from './TableListItem.vue';
import ExpandableSection from './ExpandableSection.vue';

export default {
    name: "TableList",
    components: {
        TableListItem,
        ExpandableSection,
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        data: {
            type: Array,
            default: Array,
        },
        expandable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>