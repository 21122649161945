<template>
    <BaseChip v-bind="$props" v-on="$listeners">
        <ApplicationNameCell :application="application" noTruncate/>
    </BaseChip>
</template>

<script>

import BaseChip from './BaseChip.vue';
import ApplicationNameCell from './table/ApplicationNameCell.vue';

export default {
    name: "ApplicationChip",
    extends: BaseChip,
    components: {
        ApplicationNameCell,
        BaseChip
    },
    props: {
        application: {
            type: Object,
            required: true,
            default: Object,
        }
    },
}


</script>
