<template functional>
    <div class="d-flex grow justify-center align-center">
        <div style="width: 24px;"></div>
        <div class="tooltip">
            <input class="mx-2" style="width: 18px; height: 18px; cursor: pointer;" type="checkbox" :disabled="props.accessGrid.accessChangeDisabled(props.item[props.col.value].status)" :checked="props.accessGrid.getChangeValueOf(props.item.id, props.col.value)" @change="props.onChange" :data-user-id="props.item.id" :data-app-id="props.col.value" /> 
            <div class="tooltiptext">{{ props.accessChangeTooltipText(props.item[props.col.value].status) }}</div>
        </div>
        <div style="width: 24px;">
            <div class="tooltip" v-show="props.col.application.administrators.some(admin => admin.id === props.item.id)">
                <i class="v-icon notranslate mdi mdi-shield-account theme--light"></i>
                <div class="tooltiptext">{{ props.col.application.name }} admin</div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
/* Tooltip container */
.tooltip {
    position: relative;
    display: flex;
}

/* Tooltip text */
.tooltip .tooltiptext {
    top: -6px;
    left: 24px;
    text-wrap: nowrap;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 4px 8px;
    margin: 0 8px;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    opacity: 0.7
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}
</style>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        onChange: {
            type: Function,
            required: true,
        },
        col: {
            type: Object,
            required: true,
        },
        accessGrid: {
            type: Object,
            required: true,
        },
        accessChangeTooltipText: {
            type: Function,
            required: true,
        }
    }
}
</script>