<template>
    <div class="mb-2" v-if="label || description">
        <div class="d-flex align-center">
            <span class="text-body-2"><b>{{ label }}</b></span>
            <span class="pl-2 text-body-2 color-grey" v-if="optional">(Optional)</span>
            <InfoTooltip v-if="info" class="ml-1" :text="info" top/>
        </div>
        <div v-if="description" class="text-caption" v-html="description"></div>
    </div>
</template>

<script>
import InfoTooltip from "@/components/InfoTooltip.vue";

export default {
    components: {InfoTooltip},
    props: {
        description: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            type: String,
            required: false,
            default: "",
        },
        info: {
            type: String,
            required: false,
            default: "",
        },
        optional: {
            type: Boolean,
            required: false,
            default: false,
        },
    }
}
</script>