<template>
    <v-tooltip v-bind="$props" v-on="$listeners" v-model="show">
        <template v-slot:activator="{}">
            <span style="overflow-y: hidden;" @mouseover="mouseover" @mouseleave="mouseleave">
                <slot name="truncate"/>
            </span>
        </template>
        <slot>{{ text }}</slot>
    </v-tooltip>
</template>

<script>
import { VTooltip } from 'vuetify/lib';

const textTruncateClass = "text-truncate"

export default {
    extends: VTooltip,
    props: {
        // tooltip text
        text: {},
        // for text we might limit vertical height
        verticalTruncate: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        mouseover(event) {
            // simple way of checking if we should display a tooltip for text that can't fit vertically
            // this does not show an elipsis
            if (this.verticalTruncate) {
                this.show = event.target.offsetHeight < event.target.scrollHeight
                return
            }

            let target = event.target
            if (!target.classList.contains(textTruncateClass) && target.parentNode.classList.contains(textTruncateClass)) {
                target = target.parentNode
            }

            if (target) {
                this.show = target.offsetWidth < target.scrollWidth
            }
        },
        mouseleave() {
            this.show = false
        }
    }
}

</script>



