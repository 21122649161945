<template>
    <span v-if="parsedDate">
        <span v-if="relative">{{ relativeFormat() }}</span>
        <span v-else-if="date">{{ dateFormat() }}</span>
        <span v-else-if="dayMonth">{{ dayMonthFormat() }}</span>
        <span v-else-if="phrase">{{ phraseFormat() }}</span>
        <span v-else>{{ standardFormat() }}</span>
    </span>
</template>

<script>
import { DateTime } from 'luxon'

export default {
    name: "DateFormat",
    data() {
        return {
            parsedDate: null,
        }
    },
    props: {
        dateString: {
            type: String,
            required: true,
        },
        relative: {
            type: Boolean,
            required: false,
        },
        dayMonth: {
            type: Boolean,
            required: false,
        },
        phrase: {
            type: String,
            required: false,
        },
        date: {
            type: Boolean,
            required: false,
        },
        pattern: {
            type: String,
            required: false,
        },
        parseTimezone: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    mounted() {
        this.parseDate()
    },
    methods: {
        parseDate() {
            this.parsedDate = DateTime.fromISO(this.dateString, {setZone: this.parseTimezone})
        },
        standardFormat() {
            return this.parsedDate.toFormat(this.pattern || `M/d/yyyy, h:mm:ss a 'UTC'ZZ`)
        },
        relativeFormat() {
            console.log(this.parsedDate)
            return this.parsedDate.toRelative({ base: DateTime.now() })
        },
        dateFormat() {
            return this.parsedDate.toFormat('M/d/yyyy')
        },
        dayMonthFormat() {
            return this.parsedDate.toFormat('d MMM, yyyy')
        },
        phraseFormat() {
            return `${this.phrase} ${this.dayMonthFormat()}`
        }
    },
    watch: {
        dateString() {
            this.parseDate()
        }
    }
}
</script>