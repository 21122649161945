<template>
    <v-btn v-bind="$props" v-on="$listeners" class="pay-button" color="white" text>
        <slot>Subscribe</slot>
    </v-btn>
</template>

<style scoped>
.pay-button {
    background: linear-gradient(248.02deg, #B00F90 12.82%, #4263EB 85.5%);
}
</style>

<script>
import {VBtn} from "vuetify/lib";

export default {
    extends: VBtn,
}
</script>