<template>
    <v-chip :color="color" :text-color="textColor" v-if="text" style="max-width: min-content;">
        <v-icon v-if="icon" :color="iconColor">{{ icon }}</v-icon>
        <v-img v-if="image" :src="image" height="16px" width="16px" contain class="px-3" />
        {{ text }}
    </v-chip>
</template>

<script>
const initialState = {
    color: "#f4f4f4",
    textColor: "black",
    text: "Not Enrolled",
    icon: "mdi-circle-medium",
    iconColor: "grey",
    image: null
}

export const enrollmentStatesConfig = {
    "NOT_ENROLLED": {
        ...initialState,
        text: "Not Enrolled",
    },
    "ENROLLED": {
        ...initialState,
        text: "Enrolled",
        color: "success-bg",
        textColor: "black",
        image: require('@/assets/logo-icon-green.svg'),
        icon: null,
    },
    "EMAIL_SENT": {
        ...initialState,
        text: "Enrollment email sent",
    },
    "EMAIL_CLICKED": {
        ...initialState,
        text: "Enrollment email clicked",
    },
}

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            ...initialState,
        }
    },
    mounted() {
        this.update();
    },
    methods: {
        reset() {
            this.color = initialState.color
            this.textColor = initialState.textColor
            this.text = initialState.text
            this.icon = initialState.icon
            this.iconColor = initialState.iconColor
            this.image = initialState.image
        },
        update() {
            // don't show enrollment state for admin
            if (this.item.type === "ADMIN") {
                this.reset()
                this.text = "";
                return;
            }

            const config = enrollmentStatesConfig[this.item.idpStatus]

            if (config) {
                this.color = config.color
                this.textColor = config.textColor
                this.text = config.text
                this.icon = config.icon
                this.iconColor = config.iconColor
                this.image = config.image
            } else {
                this.reset()
                this.text = ""
            }
        }
    },
    watch: {
        item: function() {
            this.reset()
            this.update()
        }
    },
}

</script>
