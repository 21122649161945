<template>
    <div v-if="shouldRender" class="grid-layout grid-basis-auto align-center">
        <GroupedApps v-if="includeNeutral" :applications="neutralApps" :max="max" color="success" :size="size"/>
        <GroupedApps v-if="includeSensitive" :applications="sensitiveApps" :max="max" color="warning" :size="size"/>
        <GroupedApps v-if="includeRestricted" :applications="restrictedApps" :max="max" color="error" :size="size"/>
    </div>
</template>

<script>
import GroupedApps from './GroupedApps.vue';

export default {
    props: {
        applications: {
            type: Array,
            default: Array,
        },
        max: {
            type: Number,
            default: 3,
        },
        size: {
            type: Number,
            default: 24
        },
        scopes: {
            default: Object,
        },
        categories: {
            type: Array,
            default: () => ["RESTRICTED", "SENSITIVE", "NEUTRAL"],
        },
    },
    computed: {
        shouldRender() {
            return this.restrictedApps.concat(this.sensitiveApps).concat(this.neutralApps).flat().length
        },
        includeRestricted() {
            return this.categories.includes("RESTRICTED")
        },
        includeSensitive() {
            return this.categories.includes("SENSITIVE")
        },
        includeNeutral() {
            return this.categories.includes("NEUTRAL")
        },
        restrictedApps() {
            return this.includeRestricted ? this.getAppsByCategory(this.applications, "RESTRICTED") : []
        },
        sensitiveApps() {
            return this.includeSensitive ? this.getAppsByCategory(this.applications, "SENSITIVE") : []
        },
        neutralApps() {
            return this.includeNeutral ? this.getAppsByCategory(this.applications, "NEUTRAL") : []
        },
        allAppSets() {
            return [
                this.restrictedApps.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {}),
                this.sensitiveApps.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {}),
                this.neutralApps.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {})
            ]
        }
    },
    methods: {
        getAppsByCategory(apps, category) {
            return apps.filter(a => a.scopes.some(s => !category || this.scopes[s].category === category));
        },
    },
    components: { GroupedApps }
}
</script>