<template>
    <div class="d-flex align-center">
        <UserAvatar :user="item" :size="size"/>
        <span class="ml-2 d-flex align-center">
            <a v-if="$listeners.onclick" @click="$emit('onclick')" class="mr-2"><UserName :user="item" :showEmail="showEmail"></UserName></a>
            <span v-else><UserName :user="item" :showEmail="showEmail"/></span>
        </span>
    </div>
</template>

<script>
import UserAvatar from '@/components/UserAvatar.vue';
import UserName from '@/components/UserName.vue';

export default {
    name: "UserCell",

    components: { 
        UserAvatar,
        UserName,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        showEmail: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: Number,
            default: 24,
        }
    },
}

</script>



