<template>
    <v-data-table :items="scopes" :headers="headers" :search="search"/>
</template>

<script>
import SecurityScopesCell from "./SecurityScopesCell.vue";
export default {
    props: {
        clientIds: {
            type: Array,
            required: true,
        },
        tokenName: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            scopes: [],
            search: "",
            headers: [
                {
                    text: "Scope name",
                    value: "name",
                },
                {
                    text: "Type",
                    value: "type",
                },
                {
                    text: "Description",
                    value: "description",
                },
            ]
        };
    },
    methods: {
        getScopes() {
            this.$http.post(`/api/v1/oauthgrants/scopes`, { clientIds: this.clientIds }).then(response => {
                this.scopes = response.data.map(scope => {
                    return SecurityScopesCell.formatScope(scope);   
                });
            }).catch(error => {
                console.error(error);
            })
        },
    },
    watch: {
        tokenName() {
            this.getScopes();
        }
    },
    mounted() {
        this.getScopes();
    }
}

</script>

