<template>
    <v-tooltip top max-width="300px">
        <template v-slot:activator="{ on }">
            <span v-on="on">
                {{ header.text }} <v-icon class="mx-1" size="16px" color="grey">mdi-information</v-icon>
            </span>
        </template>
        An application is a website or system that a user is granted access to. YeshID creates an application
        list from OAuth tokens that were granted access in Google Workspace and manually added applications
        in the Integrations screen.
    </v-tooltip>
</template>

<script>
export default {
    name: "ApplicationHeader",
    props: {
        header: {
            type: Object,
            required: true
        }
    }
}
</script>