<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="filterConfig">
        <template v-slot:item="item">
            <UserLifecycleStatus :user="item.data" :yesh-idp-configured="gwsSamlConnected"/>
        </template>
    </FilterDropdownSelector>
</template>

<script>
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'
import UserLifecycleStatus, { statuses } from '@/components/UserLifecycleStatus.vue'
import { mapGetters } from "vuex";

const text = "YeshID status"
const type = "status"

export default {
    extends: FilterDropdownSelector,
    computed: {
        ...mapGetters({
            gwsSamlConnected: "gwsSamlConnected",
        }),
        filterConfig() {
            return this.$api.filters.buildFilter(text, type,
                Object.keys(statuses).map(id => {
                    let conditions = []
                    let operator = "="
                    if (Array.isArray(statuses[id].filter)) {
                        conditions = statuses[id].filter.map(f => this.$api.filters.buildFilterItem(f.type, f.value, "", f.op))
                        operator = "OR"
                    }

                    return this.$api.filters.buildFilterItem(
                        "status",
                        id,
                        statuses[id].text,
                        operator,
                        { status: id },
                        conditions
                    )
                }),
            )
        }
    },
    props: {
    },
    components: {
        FilterDropdownSelector,
        UserLifecycleStatus,
    },
    type,
    text, 
}
</script>