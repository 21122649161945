<template>
    <v-chip class="base-chip pl-2 pr-4" v-bind="$props" v-on="$listeners"
        :color="colors[type].bg" :close="!!$listeners['click:close']">
        <v-icon :color="colors[type].iconColor">{{ colors[type].icon }}</v-icon>
        <slot/>
    </v-chip>
</template>

<script>
import { VChip } from 'vuetify/lib';

const base = {
    text: "black",
    icon: "mdi-circle-medium",
}

export default {
    extends: VChip,
    props: {
        type: {
            type: String,
            default: "info",
        },
    },
    data() {
        return {
            colors: {
                success: {
                    ...base,
                    bg: "success-bg",
                    iconColor: "success-icon"
                },
                error: {
                    ...base,
                    bg: "error-bg",
                    iconColor: "error-icon"
                },
                warning: {
                    ...base,
                    bg: "warning-bg",
                    iconColor: "warning-icon"
                },
                blue: {
                    ...base,
                    bg: "blue-bg",
                    iconColor: "blue-icon"
                },
                info: {
                    ...base,
                    bg: "info-bg",
                    iconColor: "info-icon"
                }
            }
        }
    },
}

</script>

<style scoped>
.base-chip.v-chip--pill .v-avatar {
    margin-left: -8px;
    width: 24px !important;
    height: 24px !important;
}

.base-chip img {
    width: 24px;
    height: 24px;
}
</style>
