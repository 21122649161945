<template>
    <BaseDialog :show="dialogToggle" :loading="loading" :close="close" width="800">
        <template slot="title">Import applications</template>
        <template slot="content">
            <div class="grid-layout-column grid-gap-lg">
                <ImportApplicationsForm ref="importApps" :value.sync="csv" @error="handleError" @completed="completed" @applicationsImported="applicationsImported" />
                <AlertBanner type="error" v-if="error">Error during application import: {{ error }}</AlertBanner>
            </div>
        </template>
        <template slot="actions">
            <v-btn @click="close()" :loading="loading" elevation="0" class="mr-2" text outlined>Cancel</v-btn>
            <v-btn @click="submit" :loading="loading" elevation="0" color="primary" :disabled="!csv">Import application list</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog.vue';
import ImportApplicationsForm from "./ImportApplicationsForm.vue";
import AlertBanner from '@/components/AlertBanner.vue';

export default {
    components: {
        BaseDialog,
        ImportApplicationsForm,
        AlertBanner
    },
    props: {
        toggle: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            loading: false,
            error: "",
            formValid: false,
            csv: null,
        }
    },
    computed: {
        dialogToggle: {
            get() {
                return this.toggle;
            },
            set(value) {
                this.$emit('update:toggle', value);
            }
        },
    },
    methods: {
        close() {
            this.$refs.importApps.clear()
            this.loading = false
            this.dialogToggle = false
        },
        submit() {
            this.loading = true
            this.$refs.importApps.submit()
        },
        handleError(e) {
            this.error = e
            this.loading = false
        },
        completed() {
            this.$emit("completed")
            this.close()
        },
        applicationsImported() {
            this.$emit('applicationsImported')
        }
    },
    watch: {
        toggle() {
            this.$refs.importApps.clear()
        },
    },
};
</script>
