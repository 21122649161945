
<template>

    <v-expansion-panels flat>        
        <v-expansion-panel>
            <v-expansion-panel-header>
                <div class="text-subtitle">Advanced Options</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <slot />
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>


</template>

<script>

export default {
    name: "AdvancedOptionsContainer",
    props: {
        
    },
}

</script>

<style scoped>

    .v-expansion-panels {
        border: 1px solid #E0E0E0;
    }

</style>