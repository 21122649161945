<template>
    <BaseDialog :show="toggle" title="Changes not applied" :close="cancel" width="400px">
        <template slot="content">
            <div class="text-body-2">{{ text }}</div>
        </template>

        <template slot="actions">
            <v-btn @click="cancel" elevation="0">Cancel</v-btn>
            <v-btn class="white--text" color="error-text" @click="handleRouteChange" elevation="0">Leave</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from './dialog/BaseDialog.vue'

export default {
    props: {
        enabled: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "Are you sure you want to leave without applying changes?"
        }
    },
    data() {
        return {
            toggle: false,
            next: null,
        };
    },
    mounted() {
        window.onbeforeunload = this.handleBrowserNav
    },
    unmounted() {
        window.onbeforeunload = null
    },
    methods: {
        handleBrowserNav() {
            if (this.enabled) {
                return this.text
            }
            else {
                return null
            }
        },
        handleRouteChange() {
            this.toggle = false
            const next = this.next
            if (next) {
                this.next = null
                window.onbeforeunload = null
                next()
            }
        },
        cancel() {
            this.next = null
            this.toggle = false
        },
        prepareToExit(to, from, next) {
            if (this.enabled) {
                this.toggle = true
                this.next = next
                next(false)
            } else {
                next()
            }
        }
    },
    components: { BaseDialog }
}
</script>
