<template>
    <v-container class="fill-height">
        <div style="flex-grow: 1"></div>
            <div class="d-flex flex-column align-center">
                <div class="lds-ring">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
                <div class="text-caption pa-2">
                    Loading{{ text ? ` ${text}` : "" }}...
                </div>
            </div>
        <div style="flex-grow: 1"></div>
    </v-container>
</template>

<style scoped lang="scss">
.lds-ring {
    position: relative;
    width: 84px;
    height: 84px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 84px;
    height: 84px;
    border: 6px solid $primary;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $primary transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
</style>

<script>
export default {
    props: {
        text: {
            type: String,
            default: "",
        }
    }
}
</script>