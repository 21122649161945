<!-- 
    this is the global toast component which is used to show the toast message
    the toast message is triggered by the $root.$emit('toast', message, type) event
    and can be emitted from any component
    
    - the message is the text to show
    - the type is the type of the message, it can be: success, error, info, warning
-->
<template>
        <v-snackbar :timeout="5000" :bottom="true" :right="true" v-model="toast.show" z-index="9999">
            <v-row align="center">
                <v-col cols="auto">
                    <v-icon color="green" v-if="toast.type == 'success'">mdi-check-circle</v-icon>
                    <v-icon color="red" v-if="toast.type == 'error'">mdi-alert-circle</v-icon>
                    <v-icon color="blue" v-if="toast.type == 'info'">mdi-information</v-icon>
                    <v-icon color="orange" v-if="toast.type == 'warning'">mdi-alert</v-icon>
                    <v-progress-circular color="blue" v-if="toast.type == 'progress'" size=24 indeterminate />
                </v-col>
                <v-col>
                    <b data-cy="toast-msg">{{ toast.message }}</b>
                </v-col>
            </v-row>

            <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="toast.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>

        </v-snackbar>
</template>


<script>
    export default {
        name: 'GlobalToast',
        data: () => ({
            toast: {
                show: false,
                message: '',
                type: 'info'
            }
        }),
        mounted: function () {
            this.$root.$on('toast', (message, type) => {
                this.toast.message = message;
                this.toast.type = type;
                this.toast.show = true;
            });
        }
    }

</script>