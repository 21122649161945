<template>
    <BaseDialog :show="dialogToggle" title="What are scopes?" width="640px" :close="close">
        <template slot="content">
            <div class="text-body-2">
                <p>
                    Scopes provide the ability to limit how much access an application has to your information and environment.
                </p>
                <p>
                    We classify scopes into three categories: Neutral, Sensitive, and Restricted.
                </p>
                <ul>
                    <li><b>Neutral</b>: Typically involves ability to view less critical information like your email address, or settings and alerts.</li>
                    <li><b>Sensitive</b>: Usually associated with administrator type privileges like managing users.</li>
                    <li><b>Restricted</b>: Ability to read and write personal data.</li>
                </ul>
            </div>
        </template>
        <template slot="actions">
            <v-btn color="text-secondary" outlined text elevation="0" @click="close">Close</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog.vue'

export default {
    components: {
        BaseDialog,
    },
    props: {
        toggle: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        close() {
            this.dialogToggle = false
        },
    },
    computed: {
        dialogToggle: {
            get() {
                return this.toggle
            },
            set(value) {
                this.$emit('update:toggle', value)
            }
        }
    },
}

</script>
