<template>
    <div class="d-flex align-center" v-if="noTruncate">
        <ApplicationIcon v-if="!rightIcon && !hideIcon" class="mr-2" :application="application" :size="size" />
        <span ref="appName">
            <a v-if="hasOnClick" @click="$emit('onclick')" class="mr-2">{{ formattedName }}</a>
            <span v-else>{{ formattedName }}</span>
        </span>
        <ApplicationIcon v-if="rightIcon && !hideIcon" class="ml-2" :application="application" :size="size" />
        <v-icon v-if="application.forbidden" color="error">mdi-alert-circle-outline</v-icon>
    </div>
    <TooltipWrapper top v-else>
        <template slot="truncate">
            <div class="d-flex align-center">
                <ApplicationIcon v-if="!rightIcon && !hideIcon" class="mr-2" :application="application" :size="size" />
                <span :class="`${cols ? 'col-' + cols : ''} text-truncate`" ref="appName">
                    <a v-if="hasOnClick" @click="$emit('onclick')" class="mr-2">{{ formattedName }}</a>
                    <span v-else>{{ formattedName }}</span>
                    <v-icon v-if="application.forbidden" color="error">mdi-alert-circle-outline</v-icon>
                </span>
                <ApplicationIcon v-if="rightIcon && !hideIcon" class="ml-2" :application="application" :size="size" />
            </div>
        </template>
        {{ formattedName }}
    </TooltipWrapper>
</template>

<script>
import ApplicationIcon from '@/components/ApplicationIcon.vue'
import TooltipWrapper from '@/components/TooltipWrapper.vue'

export default {
    components: {
        ApplicationIcon,
        TooltipWrapper,
    },
    props: {
        // the application object
        application: {
            type: Object,
            required: true
        },
        // the size of the icon in pixels
        size: {
            type: Number,
            required: false,
            default: 24
        },
        noTruncate: {
            type: Boolean,
            required: false,
            default: false
        },
        cols: {
            type: String,
        },
        rightIcon: {
            type: Boolean,
            default: false,
        },
        hideIcon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
        }
    },
    computed: {
        hasOnClick: function() {
            return !!this.$listeners.onclick
        },
        formattedName: function () {
            let name = this.application.displayName || this.application.name || this.application.domain

            if (!name) {
                name = 'Unknown'
            }

            return name
        }
    },
}

</script>



