<template>
    <BaseDialog :show="dialogToggle" :title="`Block ${application.name}`" width="640px" :close="close">
        <template slot="content">
            <div class="text-body-2">
                <b>Blocking an application</b>
                <p>
                    While instant blocking is not possible, we've simplified the process for your convenience. Follow these steps:
                </p>
                <ol type="1">
                    <li><b>Download the CSV File</b>: We've generated a CSV file containing all OAuth tokens for {{ application.name }}. You can download it
                        <ExportCsv :tableHeaders="tableHeaders" :csvFilename="csvFilename" :tableData="tableData">
                            <template #activator="{ on, attrs }"><a v-on="on" v-bind="attrs">here</a>.</template>
                        </ExportCsv>
                    </li>
                    <li><b>Access the Admin Console</b>: In Google Workspace Admin Console, navigate to <a href="https://admin.google.com/ac/owl/list?tab=configuredApps" target="_blank">Menu > Security > Access and data control > API controls > Manage Third-Party app access</a>.</li>
                    <li><b>Select Bulk Update List</b>: Click the "Bulk Update List" option.</li>
                    <li><b>Skip Steps 1 and 2</b>: Google's instructions for steps 1 and 2 are unnecessary as we've already provided the CSV file.</li>
                    <li><b>Upload CSV File</b>: Proceed to step 3 of Google's instructions and upload the downloaded CSV file.</li>
                    <li>Press the Upload Button.</li>
                </ol>
            </div>
        </template>
        <template slot="actions">
            <v-btn color="primary" text @click="close">Done</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import ExportCsv from '@/components/ExportCsv.vue'
import BaseDialog from '@/components/dialog/BaseDialog.vue'

export default {
    components: {
        BaseDialog,
        ExportCsv
    },
    props: {
        toggle: {
            type: Boolean,
            required: true
        },
        application: {
            type: Object,
            required: false,
            default: Object,
        },
    },
    data() {
        return {
            loading: false,
            tableHeaders: [{
                value: "appName",
                text: "App Name",
            }, {
                value: "type",
                text: "Type",
            }, {
                value: "id",
                text: "Id",
            }, {
                value: "orgUnit",
                text: "Org unit",
            }, {
                value: "access",
                text: "Access",
            }],
        }
    },
    methods: {
        close() {
            this.dialogToggle = false
            this.loading = false
        },
    },
    computed: {
        csvFilename() {
            return this.application.name ? `block ${this.application.name}` : "block-app"
        },
        tableData() {
            if (!this.application.clientIds) {
                return []
            }

            const tableData = this.application.clientIds.map(id => {
                const appRow = {
                    appName: this.application.name,
                    type: id.startsWith("com.") ? "Android" : "Web Application",
                    id,
                    orgUnit: "/",
                    access: "BLOCKED",
                }

                if (appRow.type === "Android") {
                    return [appRow, { ...appRow, type: "iOS" }]
                }

                return appRow
            }).flat()

            return tableData
        },
        dialogToggle: {
            get() {
                return this.toggle
            },
            set(value) {
                this.$emit('update:toggle', value)
            }
        }
    },
}

</script>
