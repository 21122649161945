<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="filterConfig"/>
</template>

<script>
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'

const text = "Provisioning status"
const type = "provisioningStatus"

const provisioningStatusConfig = {
    "PROVISIONED": {
        text: "Provisioned",
    },
    "TO_BE_PROVISIONED": {
        text: "To be provisioned",
    },
    "TO_BE_DEPROVISIONED": {
        text: "To be deprovisioned",
    },
    "DEPROVISIONED": {
        text: "Not provisioned",
        op: "OR",
        type: "provisioningStatus",
        filter: [{
            field: "provisioningStatus",
            value: null,
            op: "=",
        }, {
            field: "provisioningStatus",
            value: "DEPROVISIONED",
            op: "=",
        }],
    },
}
export default {
    extends: FilterDropdownSelector,
    props: {
    },
    data() {
        return {
            filterConfig:
                this.$api.filters.buildFilter(text, type,
                    Object.keys(provisioningStatusConfig).map(id =>
                        this.$api.filters.buildFilterItem('provisioningStatus', id, provisioningStatusConfig[id].text, "=", {},
                            provisioningStatusConfig[id].filter?.map(f => this.$api.filters.buildFilterItem(f.field, f.value, "", f.op)) || []
                        )
                    ),
                ),
        }
    },
    components: {
    FilterDropdownSelector,
},
    type,
    text,
}
</script>