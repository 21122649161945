<template>
    <BaseDialog :show="dialogToggle" :title="`Revoke ${application.name}`" width="640px" :close="close">
        <template slot="content">
            <div class="text-body-2">
                <p>
                    You will revoke all OAuth tokens associated with {{ application.name }}
                </p>
                <p>
                    This means any user who has granted access to the application will have their access revoked.
                </p>
                <p>
                    <b>Here's how it works:</b>
                </p>
                <ol type="1">
                    <li class="mb-4"><b>List Tokens</b>: We will first gather all known OAuth tokens associated with the applications. This includes tokens for all users who have granted access.</li>
                    <li><b>Iterate and Revoke</b>: We will then iterate through each token and revoke its corresponding refresh token. This effectively invalidates the user's access to the application.</li>
                </ol>
                <div class="grid-layout-column mt-4">
                    <v-checkbox class="ma-0 pa-0" dense v-model="markForbidden" hide-details>
                        <template slot="label"><span class="text-body-2 black--text">Mark {{ application.name }} as forbidden</span></template>
                    </v-checkbox>
                    <AlertBanner v-if="this.markForbidden" type="info">
                        You will be notified when users provide OAuth access to forbidden applications.
                    </AlertBanner>
                    <AlertBanner v-if="error" type="error">
                        {{ error }}
                    </AlertBanner>
                </div>
            </div>
        </template>
        <template slot="actions">
            <v-btn color="text-secondary" outlined text @click="close">Close</v-btn>
            <v-btn color="primary" @click="revokeAccess" :disabled="loading" :loading="loading" elevation="0">Revoke</v-btn>
        </template>
    </BaseDialog>
</template>

<script>
import AlertBanner from '@/components/AlertBanner.vue'
import BaseDialog from '@/components/dialog/BaseDialog.vue'

export default {
    components: {
        BaseDialog,
        AlertBanner
    },
    props: {
        toggle: {
            type: Boolean,
            required: true
        },
        application: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            loading: false,
            error: null,
            markForbidden: true,
        }
    },
    methods: {
        close() {
            this.dialogToggle = false
            this.loading = false
        },
        revokeAccess() {
            this.error = ""
            this.loading = true

            this.$http.delete(`/api/v1/applications/thirdparty`, { data: { displayText: this.application.name, clientIds: this.application.clientIds, markAsForbidden: this.markForbidden }})
            .catch((err) => {
                this.error = "There was an issue revoking access, please try again."
                console.log(err)
            }).finally(() => {
                this.loading = false
                this.$emit('success')
                this.dialogToggle = false
            })
        },
    },
    computed: {
        dialogToggle: {
            get() {
                return this.toggle
            },
            set(value) {
                this.$emit('update:toggle', value)
            }
        }
    },
}

</script>
