<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="internalFilterConfig">
        <template v-slot:item="item">
            <ApplicationNameCell :application="item.data" noTruncate/>
        </template>
    </FilterDropdownSelector>
</template>

<script>
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'
import ApplicationNameCell from '@/components/table/ApplicationNameCell.vue'

const text = "Applications"
const type = "applications"

export default {
    extends: FilterDropdownSelector,
    props: {
        field: {
            type: String,
            default: "applicationId"
        },
        op: {
            type: String,
            default: "="
        },
        getApplications: {
            type: Function,
        },
        getId: {
            type: Function,
            default: a => a.id,
        },
        getName: {
            type: Function,
            default: a => a.name,
        }
    },
    data() {
        return {
            applications: this.$api.state.base([]),
            filterConfig: this.$api.filters.buildFilter(text, type, []),
        }
    },
    mounted() {
        const getApplications = this.getApplications || this.$api.get.applications
        getApplications(this.applications, {
            onSuccess: () => {
                this.internalFilterConfig.items = this.applications.data.map(a =>
                    this.$api.filters.buildFilterItem(this.field, this.getId(a), this.getName(a), this.op, a)
                )
            },
        })
    },
    computed: {
        internalFilterConfig: {
            get() {
                return this.filterConfig
            },
            set(value) {
                this.filterConfig = value
            }
        }
    },
    components: {
        FilterDropdownSelector,
        ApplicationNameCell
    },
    type,
    text, 
}
</script>