<template>
    <div class="grid-layout-column">
        <h2>{{ number }}</h2>
        <div class="d-flex">
            <v-icon class="pa-0 mr-2" :color="color" size="16">mdi-circle</v-icon>
            <div class="text-subtitle-1 text-secondary--text align-content-center">{{ label }}</div>
        </div>
        <div class="text-secondary--text text-body-2">{{ subNumber }} {{ subNumberLabel }}</div>
    </div>
</template>

<script>
export default {
    props: {
        number: {
            required: true,
        },
        label: {
            required: true,
        },
        color: {
            required: true,
        },
        subNumber: {
        },
        subNumberLabel: {
        },
    }
}
</script>