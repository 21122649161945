import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';

// Load Vuex
Vue.use(Vuex);
// Create store
const store = new Vuex.Store({
    modules: {
        auth
    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            // Adding an account to an android phone likely uses webview which does not have localStorage
            // this resulted in errors loading the login page
            if (localStorage && localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        }
    },
});

// Subscribe to store updates
store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
    // Adding an account to an android phone likely uses webview which does not have localStorage
    // this resulted in errors loading the login page
    if (localStorage) {
        localStorage.setItem('store', JSON.stringify(state));
    }
});

export default store