<template>
    <FilterSearch v-bind="$props" v-on="$listeners" :filter.sync="filterConfig" />
</template>

<script>
import FilterSearch from '@/components/filters/FilterSearch.vue'

const text = "First name"
const type = "name"

export default {
    extends: FilterSearch,
    props: {
    },
    data() {
        return {
            filterConfig: this.$api.filters.buildFilter(text, type,
                [this.$api.filters.buildFilterItem("name", "", "", ":")],
            ),
        }
    },
    components: {
        FilterSearch
    },
    type,
    text, 
}
</script>