<!-- component to allow icon uploading -->

<template>
    <div>
        <InputLabelDescription v-if="edit" class="mb-0" :label="label" :optional="optional"
            description="Select a PNG file under 1 MB from your device."/>

        <v-layout class="grid-layout grid-basis-auto align-center">
            
            <div class="pa-1 rounded" style="border: 1px #e0e0e0 solid; height: 50px; width: 50px;">
                <ApplicationIcon style="z-index: 10" :application="applicationWithIcon()" :size="40" placeholder-icon="mdi-file"/>
            </div>

            <FileInput :edit="edit" fileFormat="image/png"
                fileFormatError="Invalid file format. Please upload an icon in PNG format only."
                @input="file => $emit('update:icon', file)"
                :maxSize="1024*1024"
            >
                <template #activator="{ on }">
                    <v-btn v-on="on" elevation="0" v-if="edit">Upload Icon</v-btn>
                </template>
            </FileInput>
        </v-layout>
    </div>
</template>

<script>
import FileInput from './FileInput.vue'
import ApplicationIcon from '@/components/ApplicationIcon.vue'
import InputLabelDescription from './InputLabelDescription.vue'


    export default {
        name: "IconFileInput",
        components: {
            FileInput,
            ApplicationIcon,
            InputLabelDescription
        },
        props: {
            application: {
                type: Object,
                required: false,
                default: Object,
            },
            edit: {
                type: Boolean,
                required: false,
                default: true,
            },
            label: {
                type: String,
                default: "Icon",
            },
            optional: {
                type: Boolean,
                default: false,
            },
            // the icon data uri to sync to
            icon: {
                validator: prop => typeof prop === 'string' || prop === null,
                required: true,
                default: '',
            }
        },
        methods: {
            // if the user selects a new icon, return the application with the new icon
            applicationWithIcon() {
                if (this.icon !== this.application.icon) {
                    return {
                        ...this.application,
                        icon: this.icon,
                    }
                }

                return this.application
            },
        }
    }

</script>