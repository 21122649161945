


<template>
    <!-- dropdown menu with arrangeable table headers and csv export option -->
    <v-menu>
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                    <v-btn icon v-bind="{ ...attrsMenu, ...attrsTooltip }" v-on="{ ...onMenu, ...onTooltip }">
                        <!-- kabob menu icon  -->
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <span>View actions</span>
            </v-tooltip>
        </template>
        <v-list>

            <!-- export csv button -->
            <ExportCsv :csvFilename="csvFilename" :tableHeaders="tableHeaders" :tableData="tableData">
                <template #activator="{ on, attrs }">
                    <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title>Export CSV</v-list-item-title>
                        <v-list-item-action>
                            <v-icon>mdi-file-export</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </ExportCsv>

            <!-- print button -->
            <v-list-item @click="printTable">
                <v-list-item-title>Print</v-list-item-title>
                <v-list-item-action>
                    <v-icon>mdi-printer</v-icon>
                </v-list-item-action>
            </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>
import ExportCsv from './ExportCsv.vue';

export default {
    props: {
        tableHeaders: {
            type: Array,
            required: true
        },
        tableData: {
            type: Array,
            required: true
        },
        csvFilename: {
            type: String,
            required: true,
        }
    },
    data: function () {
        return {
            draggedHeader: null,
            headerMenu: [],
            localHeaders: [], // local copy of the table headers   
        };
    },
    methods: {
        printTable() {
            // todo: print the whole table, not just the visible rows and columns
            window.print();
        }
    },
    components: { ExportCsv }
}

</script>
