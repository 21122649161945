<template>
    <div class="grid-layout-column grid-gap-lg grid-basis-auto">
        <div class="numbered-list">
            <ol class="body-2">
                <li>
                    <div>
                        <b>Download the App List Template:</b> <a href="https://storage.googleapis.com/yeshid-app-production-assets/yeshid-import-applications.csv" download>Click here to
                            download
                            a simple CSV file</a>.
                    </div>
                </li>
                <li>
                    <div>
                        <b>Fill in the Info (Quick & Easy):</b> Open the downloaded CSV and fill in the following for each
                        app:
                        <ul>
                            <li><b>Application Name:</b> Enter the name (e.g., Salesforce, Dropbox) or create a custom name.
                            </li>
                            <li><b>Login Link (Optional):</b> YeshID usually has this information and can populate it for
                                you,
                                but if you have a unique login link, you might want to add it here.</li>
                            <li><b>Owner(s):</b> List the email addresses of those managing access (e.g. jane@company.com;
                                bob@company.com)</li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div>
                        <b>Upload and We Do the Rest:</b> Once you've filled out the information, save the CSV file and
                        click
                        "Upload your CSV" below. Select the file from your computer, and that's it! We'll handle importing
                        your
                        applications into YeshID.
                    </div>
                </li>
            </ol>
        </div>
        <div v-if="importApplicationsCsv" class="text-bold body-2">
            <v-icon>mdi-paperclip</v-icon>
            {{ importApplicationsCsv.name }}
        </div>
        <div>
            <v-btn @click="triggerFileInput" elevation="0" outlined color="primary">
                <span v-if="!importApplicationsCsv">Upload your CSV</span>
                <span v-else>Upload again</span>
            </v-btn>
        </div>
        <input type="file" ref="importCsvFileInput" style="display: none" @change="importCsvUploaded"
            accept="text/csv" />
    </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    data: function () {
        return {
            importError: "",
            importApplicationsCsv: null,
            formValid: false,
        }
    },
    methods: {
        ...mapActions(["updateOrgSettings"]),
        clear() {
            this.importApplicationsCsv = null
            this.$emit('update:value', null)
            this.handleError(null)
        },
        validate() {
            return !!this.importApplicationsCsv
        },
        handleError(e) {
            this.$emit("error", e)
            this.importError = e
        },
        submit() {
            this.importApplications()
        },
        triggerFileInput() {
            this.$refs.importCsvFileInput.click()
        },
        importCsvUploaded(e) {
            this.importApplicationsCsv = e.target.files[0]
            this.$emit('update:value', this.importApplicationsCsv)
            this.handleError(null)
        },
        importApplications() {
            let formData = new FormData()
            formData.append('applications', this.importApplicationsCsv)

            this.$http.post('/api/v1/applications/import', formData)
            .then((resp) => {
                this.showImport = false

                if (resp.data.numAddedApps || resp.data.numUpdatedApps) {
                    let msg = ''
                    if (resp.data.numAddedApps) {
                        msg += `Added ${resp.data.numAddedApps} application(s). `
                    }
                    if (resp.data.numUpdatedApps) {
                        msg += `Updated ${resp.data.numUpdatedApps} application(s).`
                    }
                    this.$root.$emit('toast', msg, 'success')
                    this.$emit('applicationsImported')
                } else {
                    this.$root.$emit('toast', 'The file contained no new applications or modifications to apply', 'success')
                }
            })
            .catch(error => {
                console.log(error)
                let errmsg = error?.response?.data?.message
                this.importError = errmsg ? errmsg : error
                this.handleError(this.importError)
            })
            .finally(() => {
                if (!this.importError) {
                    this.updateOrgSettings({ addApplicationsCompleted: true })
                    this.$emit("completed")
                }
            })
        },
    },
};
</script>
