<template>
    <FilterDropdownSelector v-bind="$props" v-on="$listeners" :filter.sync="filterConfig">
        <template v-slot:item="item">
            <UserType :user="item.data"/>
        </template>
    </FilterDropdownSelector>
</template>

<script>
import FilterDropdownSelector from '@/components/filters/FilterDropdownSelector.vue'
import UserType, { userTypesConfig } from '@/components/UserType.vue'

const text = "User type"
const type = "userType"

export default {
    extends: FilterDropdownSelector,
    props: {
    },
    data() {
        return {
            filterConfig:
                this.$api.filters.buildFilter(text, type,
                    Object.keys(userTypesConfig).map(id =>
                        this.$api.filters.buildFilterItem("type", id, userTypesConfig[id].text, "=", { type: id },
                            userTypesConfig[id].filter?.map(f => this.$api.filters.buildFilterItem(f.field, f.value, "", f.op)) || []
                        )
                    ),
                ),
        }
    },
    components: {
        FilterDropdownSelector,
        UserType,
    },
    type,
    text,
}
</script>