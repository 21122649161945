<template>
    <div data-cy="username" :class="layout">
        <div :class="layout">{{ user.name | truncate(20) }} {{ user.lastName | truncate(20) }}</div>
        <div v-if="showEmail" class="text-caption">{{ user.email }}</div>
    </div>
</template>

<script>

export default {
    name: "UserName",
    props: {
        user: {
            type: Object,
            required: true,
        },
        showEmail: {
            type: Boolean,
            default: false,
        },
    },
    filters: {
        truncate(value, length) {
            if (!value) return ''
            value = value.toString().trim()
            return value.length > length ? value.substring(0, length) + '...' : value
        }
    },
    computed: {
        layout() {
            return this.showEmail ? 'd-block' : 'd-inline'
        }
    }
}

</script>