<template>
    <div class="grid-layout grid-basis-auto align-center">
        <v-tooltip v-for="scope in orderedScopes(item.scopes).slice(0, max)" :key="scope.name" bottom>

            <template v-slot:activator="{ on, attrs }">
                <v-chip v-bind="attrs" v-on="on" @click="$emit('click')" color="info-bg">
                    <v-icon :color="getCategoryColor(scope)" left>mdi-circle-medium</v-icon>{{ formatScopeName(scope) }}
                </v-chip>
            </template>
            <span>{{ lookupScope(scope) }}</span>
        </v-tooltip>

        <v-chip v-if="item.scopes.length > max" @click="$emit('click')" color="info-bg">
            +{{ item.scopes.length - max }}
        </v-chip>

    </div>
</template>

<script>
const scopeName = (scope) => {
    let name = ''

    try {
        // for the scopes that look like "https://etc/auth/some.scope.name" we want to use "some.scope.name"
        // but there are others that are just a url like "https://etc/some/scope/name" so we want to turn those into "some.scope.name"
        // for consistency
        const u = new URL(scope.name)
        name = u.pathname.substring(1).replace('auth/', '').replaceAll('/', '.')
        if (name.charAt(name.length - 1) == '.') {
            name = name.substring(0, name.length - 1)
        }
        if (name == "") {
            name = scope.name // just use the url then otherwise we have nothing to show
        }
    } catch {
        // if it's not a url (eg: 'openid') then leave it alone
        name = scope.name
    }

    return name
}

const scopeDescription = (scope) => {
    return scope.description || 'No Description'
}

const scopeType = (scope) => {
    if (writeRegex.test(scopeDescription(scope))) {
        return "Write"
    } else {
        return "Read"
    }
}

// TEMP FIX: sort the scopes in each application by write until we have a better way to order them by risk
const writeRegex = /(create|manage|modify|delete|write|control|configure|edit|update)/i

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        max: {
            type: Number,
            required: false,
            default: 2
        },
    },
    methods: {
        lookupScope: function (scope) {
            return scopeDescription(scope);
        },
        formatScopeName: function (scope) {
            return scopeName(scope)
        },
        orderedScopes: function (scopes) {
            let orderedScopes = [];
            for (let scope of scopes) {
                if (writeRegex.test(this.lookupScope(scope))) {
                    orderedScopes.unshift(scope);
                }
                else {
                    orderedScopes.push(scope);
                }
            }
            return orderedScopes;
        },
        getCategoryColor(scope) {
            switch(scope.category) {
                case "NEUTRAL":
                    return "success"
                case "SENSITIVE":
                    return "warning"
                case "RESTRICTED":
                    return "error"
                default:
                    return "white"
            }
        }
    },
    formatScope: function (scope) {
        return {
            name: scopeName(scope),
            description: scopeDescription(scope),
            type: scopeType(scope),
        };
    },
    scopeName,
    scopeDescription,
    scopeType,
}

</script>