<template>
    <ApplicationsFilter v-bind="$props" v-on="$listeners"
        field="id" op="="
        :getApplications="$api.get.thirdPartyApplications"
        :getId="a => a.oauthGrantIds"
        :getName="a => a.name"/>
</template>

<script>
import ApplicationsFilter from '../User/ApplicationsFilter.vue'

const text = "Applications"
const type = "applications"

export default {
    extends: ApplicationsFilter,
    components: {
        ApplicationsFilter,
    },
    type,
    text,
}
</script>